/**
 *
 * Grid styles - foundation settings
 *
 */

// NOTE: Don't change names of variables to camel case as these are overrides for foundation config.

$global-width: 1440px;

// Breakpoints
$breakpoints: (
  small: 0,
  medium: 768px,
  large: 1024px,
  xlarge: 1280px,
  xxlarge: 1440px,
);

$print-breakpoint: large;
$breakpoint-classes: (small medium large);

// Grid
$grid-row-width: $global-width;
$grid-column-count: 12;
$grid-column-gutter: (
  small: 10px,
  medium: 20px,
);
$grid-column-align-edge: true;
$grid-column-alias: false;
$block-grid-max: 8;

@import "../../node_modules/foundation-sites/scss/foundation";
@import "../../node_modules/foundation-sites/scss/util/util";

@include foundation-flex-grid;
@include foundation-flex-classes;
@include foundation-visibility-classes;
