footer {
  font-size: 14px;
  line-height: 16px;
  margin: 0 auto;
  padding: 70px 8px 30px;

  p {
    margin-top: 0;
  }

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .footer-container {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    padding: 0 30px;

    @include breakpoint(medium) {
      flex-direction: row;
    }

    .numbers {
      margin-top: 30px;

      @include breakpoint(medium) {
        margin-top: 0;
      }

      & > a {
        display: block;
        margin-top: 16px;
        color: $colorMint;
      }

      p {
        font-weight: 300;
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 4px;
        margin-top: 0;
      }
    }

    .address {
      display: flex;
      align-items: flex-start;

      img {
        margin-right: 16px;
        height: 70px;

        @include breakpoint(large) {
          margin-right: 10px;
        }

        @include breakpoint(xlarge) {
          margin-right: 30px;
        }
      }

      p {
        font-size: 14px;
        line-height: 1;

        &:first-child {
          margin-top: 0;
        }

        strong {
          font-weight: 600;
        }
      }
    }

    .social {
      margin-top: 50px;

      @include breakpoint(medium) {
        margin-top: 0;
      }

      @include breakpoint(large) {
        margin-top: 0;
      }

      .links {
        display: flex;
        align-items: center;

        a {
          display: inline-block;
          margin-right: 10px;
        }
      }
    }
  }
}
