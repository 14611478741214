/* stylelint-disable no-descending-specificity */
.form-item {
  min-height: 110px;

  &.auto-height {
    min-height: 40px;
  }

  &__error {
    padding-left: 14px;
    color: $colorError;
    font-size: 14px;
    line-height: 20px;
  }
}

input {
  border: 1px solid $colorBorder;
  background: $colorWhite;
  color: $colorBlack;
  padding: 14px;
  height: 48px;
  display: block;
  width: 100%;

  &.error {
    border-color: $colorError;

    &:focus {
      outline-color: $colorError;
    }
  }

  &::-webkit-input-placeholder {
    color: $colorGrey;
  }

  &::-moz-placeholder {
    color: $colorGrey;
  }

  &:-ms-input-placeholder {
    color: $colorGrey;
  }

  &:-moz-placeholder {
    color: $colorGrey;
  }

  &:focus-visible {
    outline: none;
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="text"],
input[type="number"],
input[type="email"],
input[type="tel"] {
  margin: 4px 0;
}

input[type="checkbox"] {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;

  ~ label {
    cursor: pointer;
    padding-top: 2px;
    padding-bottom: 0;
    padding-left: 36px;
    display: inline-block;
    position: relative;
    color: $colorBlack;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 4px;

    span {
      font-weight: 700;
      color: $colorBlue;

      img {
        margin-left: 3px;
        transform: rotate(0);
        transition: transform 0.25s ease;
      }

      &.active {
        img {
          transform: rotate(180deg);
        }
      }
    }

    a {
      color: $colorMint;
      text-decoration: none;
      font-weight: 600;

      &:hover {
        text-decoration: underline;
      }
    }

    &::before {
      position: absolute;
      left: 0;
      top: 0;
      content: "";
      display: block;
      border: 2px solid $colorDarkGrey;
      background: $colorWhite;
      transition: all 0.25s ease;
      width: 24px;
      height: 24px;
      border-radius: 3px;
    }

    &::after {
      position: absolute;
      display: block;
      content: "";
      width: 0;
      height: 20px;
      background-image: url("../images/check.svg");
      background-repeat: no-repeat;
      background-size: 24px;
      left: 0;
      top: 0;
    }

    @media (hover: hover) {
      &:hover {
        &::before {
          background: $colorWhite;
          border-color: $colorMint;
        }
      }
    }

    &.position-fix {
      &::before,
      &::after {
        top: 4px;
      }
    }
  }

  // Error
  .-has-error & {
    ~ label {
      &::before {
        background: $colorWhite;
        border-color: $colorError;
      }

      @media (hover: hover) {
        &:hover {
          &::before {
            /* stylelint-disable-line max-nesting-depth */
            border-color: $colorError;
          }
        }
      }
    }
  }

  &:checked {
    ~ label {
      &::before {
        border-color: $colorMint;
        background: $colorWhite;
      }

      &::after {
        transition: width 0.25s ease;
        width: 20px;
      }

      &.minus {
        &::after {
          background-image: url("../images/minus.svg");
          top: 15px;
          left: 4px;
          background-size: 80%;
        }
      }
    }

    .-has-error & {
      ~ label {
        &::before {
          border-color: $colorBlue;
          background: $colorWhite;
        }

        &::after {
          transition: width 0.25s ease;
          width: 20px;
        }
      }
    }

    // Disabled
    &:disabled {
      ~ label {
        cursor: default;

        &::before {
          background: #eaeaea !important;
        }

        &::after {
          opacity: 0.5 !important;
        }
      }
    }
  }
}

.radio-group {
  &__single {
    margin: 24px 0;

    &:first-child {
      margin-top: 32px;
    }

    input[type="radio"] {
      display: none;

      &:checked + label::after {
        border-radius: 11px;
        width: 10px;
        height: 10px;
        position: absolute;
        top: 6.5px;
        left: 3px;
        content: " ";
        display: block;
        background: $colorMint;
      }

      ~ label {
        margin-bottom: 0;
        display: flex;
        align-items: baseline;
        position: relative;

        &::before {
          content: " ";
          display: inline-block;
          position: relative;
          top: 2px;
          margin: 0 5px 0 0;
          width: 16px;
          height: 16px;
          border: 1px solid $colorMint;
          border-radius: 50%;
          min-width: 16px;
          min-height: 16px;
          max-width: 16px;
          max-height: 16px;
          cursor: pointer;
        }

        span {
          margin-left: 10px;
          display: inline-block;
          line-height: 24px;
        }
      }
    }
  }

  &.-has-error {
    label {
      &::before {
        border: 1px solid $colorError !important;
      }
    }
  }

  .error {
    display: none;
    padding-left: 30px;
    color: $colorError;
  }
}

button[type="submit"] {
  background: $colorWhite;
  cursor: pointer;

  &:hover {
    background: $colorMint;
  }
}

label.uppercase {
  color: $colorMint !important;
  font-weight: 300;
  text-transform: uppercase;
  font-size: 14px !important;
  line-height: 20px !important;
}

.arrow {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-image: url(../images/arrow.svg);
  background-repeat: no-repeat;
  background-size: 12px;
  background-position: center 12px;

  &.opened {
    transform: rotate(180deg);
    background-position: center 0;
  }
}

.js-checkbox-child {
  padding-left: 36px;
}

#bouncer-error_terms-participating_on {
  padding-left: 36px;
}

.js-form-error-general {
  color: $colorError;
  font-size: 14px;
  line-height: 20px;
}
