/**
 *
 * Color styles
 *
 */

$colorWhite: #fff;
$colorBlack: #000;
$colorBlue: #00f;
$colorMint: #2c8f82;
$colorMintLight: #a0d1ca;
$colorShadow: rgba(73, 47, 0, 0.1);
$colorBorder: #ecebed;
$colorError: #f22;
$colorGrey: #ddd8e1;
$colorDarkGrey: #939598;
$colorGold: #bf9340;
$colorGoldLight: #f1dfc3;
