/**
 *
 * Base styles
 *
 */
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-size: 100%;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  background-image: url("../images/bg.jpg");
  background-repeat: no-repeat;
  background-position: center 0;
  overflow-x: hidden;
  background-size: cover;

  &.finished {
    background-position: center -280px;
  }
}

.mint {
  color: $colorMint;
}

.gold {
  color: $colorGold;
}

.no-padding-left {
  padding-left: 0;
}

.hidden {
  display: none;
}

.align-center {
  text-align: center;
}

.hide-on-large {
  display: inline-block;

  @include breakpoint(large) {
    display: none;
  }
}

.no-margin-top {
  margin-top: 0 !important;
}

.main-container {
  max-width: 1440px;
  position: relative;
  margin: 0 auto;

  &::after {
    content: "";
    width: 322px;
    height: 230px;
    background-image: url(../images/gold.png);
    background-repeat: no-repeat;
    background-position: 0 0;
    position: absolute;
    display: block;
    z-index: -1;
    background-size: 100%;
    top: 740px;
    right: 14px;
  }

  @media screen and (min-width: 370px) and (max-width: 768px) {
    &::after {
      top: 32%;
      width: 90%;
      height: 28%;
      right: 20px;
    }
  }

  @include breakpoint(medium) {
    &::after {
      top: 390px;
      width: 400px;
      height: 300px;
      right: 14px;
    }
  }

  @include breakpoint(large) {
    &::after {
      top: 520px;
      width: 530px;
      height: 410px;
      right: -20px;
    }
  }

  @include breakpoint(xlarge) {
    &::after {
      top: 340px;
      width: 640px;
      height: 490px;
      right: 14px;
    }
  }

  @media screen and (min-width: 1440px) {
    &::after {
      top: 360px;
      width: 610px;
      height: 480px;
    }
  }

  &.no-bg {
    &::after {
      background: none;
    }
  }
}

main {
  padding: 24px 8px;

  @include breakpoint(large) {
    padding: 64px 106px;
  }

  @include breakpoint(xlarge) {
    padding: 64px 156px;
  }
}

.logo {
  margin-bottom: 68px;
}

.intro {
  margin-bottom: 200px;

  @media screen and (min-width: 500px) {
    margin-bottom: 280px;
  }

  @include breakpoint(medium) {
    margin-bottom: 0;
  }
}

.quiz {
  background: $colorWhite;
  box-shadow: 4px 8px 20px 0 $colorShadow;
  padding: 24px 16px;
  margin-top: 90px;

  @include breakpoint(medium) {
    padding: 52px 72px;
  }

  &__screen {
    overflow: hidden;

    .btn {
      display: block;
      border: 1px solid $colorMint;
      padding: 10px 32px;
      color: $colorMint;
      text-decoration: none;
      font-weight: 600;
      transition: all 0.25s ease;
      margin-top: 48px;
      float: right;
      cursor: pointer;

      &:hover {
        background-color: $colorMint;
        color: $colorWhite;
      }

      &.block {
        float: none;
        width: 100% !important;
      }

      &.disabled {
        cursor: not-allowed;

        &:hover {
          background-color: $colorWhite;
          color: $colorMint;
        }
      }

      &[type="submit"] {
        width: 180px;
        height: 40px;

        &.download {
          height: auto;
        }

        .lds-ring {
          display: none;
          position: relative;
          width: 20px;
          height: 20px;
          margin-top: -2px;

          div {
            box-sizing: border-box;
            display: block;
            position: absolute;
            width: 14px;
            height: 14px;
            margin: 4px;
            border: 4px solid $colorMint;
            border-radius: 50%;
            animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            border-color: $colorMint transparent transparent transparent;

            &:nth-child(1) {
              animation-delay: -0.45s;
            }

            &:nth-child(2) {
              animation-delay: -0.3s;
            }

            &:nth-child(3) {
              animation-delay: -0.15s;
            }
          }
        }

        &.in-progress {
          span {
            display: none;
          }

          .lds-ring {
            display: inline-block;
          }
        }
      }
    }

    .progress {
      width: 100%;
      background-color: $colorGoldLight;
      height: 8px;
      margin-bottom: 40px;

      .inner {
        background-color: $colorGold;
        height: 100%;

        &.step-1 {
          width: 16.6666%;
        }

        &.step-2 {
          width: 33.3332%;
        }

        &.step-3 {
          width: 49.9998%;
        }

        &.step-4 {
          width: 66.6664%;
        }

        &.step-5 {
          width: 83.3333%;
        }

        &.step-6 {
          width: 100%;
        }
      }
    }
  }
}

.terms-container {
  p,
  label {
    font-size: 14px !important;
    line-height: 20px !important;
  }
}

body.confirm {
  background-position: center -160px;

  @media screen and (min-width: 370px) and (max-width: 768px) {
    background-position: center 0;
  }

  @include breakpoint(medium) {
    background-position: center -160px;
  }

  .main-container {
    @media screen and (min-width: 370px) and (max-width: 768px) {
      &::after {
        top: 43%;
        width: 90%;
        height: 28%;
        right: 20px;
      }
    }

    @include breakpoint(large) {
      &::after {
        top: 420px;
        width: 530px;
        height: 410px;
        right: 40px;
      }
    }

    @include breakpoint(xlarge) {
      &::after {
        top: 410px;
        width: 640px;
        height: 490px;
        right: 14px;
      }
    }
  }

  footer {
    @include breakpoint(medium) {
      padding-top: 120px;
    }

    @include breakpoint(large) {
      padding-top: 70px;
    }
  }
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.main-container.finished {
  &::after {
    background: none;
    height: 0;
  }

  height: 100%;
  display: flex;
  flex-direction: column;

  main {
    flex-grow: 1;
  }

  footer {
    width: 100%;
    flex-grow: 0;
  }

  .quiz.finished {
    margin-top: 30px;

    h2 {
      text-align: center;
    }
  }
}
