/**
 *
 * Typography styles
 *
 */

h1 {
  font-size: 32px;
  line-height: 48px;

  @include breakpoint(large) {
    font-size: 64px;
    line-height: 72px;
  }
}

h2 {
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;

  @include breakpoint(large) {
    font-size: 32px;
    line-height: 48px;
  }
}

p {
  font-size: 16px;
  line-height: 24px;

  &.small {
    font-size: 14px;
    line-height: 20px;
  }

  &.question {
    font-weight: 600;
  }

  &.text-lead {
    font-size: 20px;
    line-height: 32px;
    font-style: italic;
    font-weight: 700;
    color: $colorMint;
    margin-bottom: 40px;
  }

  &.semi {
    font-weight: 600;
    margin-bottom: 30px;
  }

  &.italic {
    font-style: italic;
    font-weight: 400 !important;
  }

  &.mint.italic {
    text-align: center;
    margin: 0 0 48px;
    font-weight: 700 !important;
    font-style: italic;
  }

  &.gold.italic {
    text-align: center;
    margin: 0 0 48px;
    font-weight: 700 !important;
    font-style: italic;
  }
}

a {
  color: $colorBlack;
  transition: color 0.25s ease;

  &:hover {
    color: $colorMint;
  }

  strong {
    font-weight: 600;
  }

  &.small {
    font-size: 14px;
    line-height: 20px;
  }
}
